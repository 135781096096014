import { ISODateTime } from "@components/intl"
import { useSecurity } from "@components/security"
import type { MetadataFragment, MetadataUserFragment } from "@graphql/types"
import { GlobalEntityLink } from "../../../components/global-entity-link"

export function EntityMetadataUser({
	user,
}: {
	user: MetadataUserFragment | null
}) {
	const { canEditSecurityUser } = useSecurity()
	if (user) {
		if (canEditSecurityUser()) return <GlobalEntityLink entity={user} />
		return <span>{user.name}</span>
	}
	return <span className="italic">Unknown user</span>
}

interface EntityMetadataProps {
	metadata: MetadataFragment
}

export function EntityMetadata({ metadata }: EntityMetadataProps) {
	return (
		<div className="flex flex-col gap-2 text-xs">
			<div className="flex truncate gap-2">
				<div className="w-12 font-bold">Creation</div>
				<ISODateTime value={metadata.createdAt} />
				<EntityMetadataUser user={metadata.createdBy} />
			</div>
			<div className="flex truncate gap-2">
				<div className="w-12 font-bold">Update</div>
				<ISODateTime value={metadata.updatedAt} />
				<EntityMetadataUser user={metadata.updatedBy} />
			</div>
		</div>
	)
}
