import { type ChangeEvent, type ReactNode, useCallback, useMemo, useRef, useState } from "react"

import { useSearchEntitiesQuery } from "@graphql/apollo"
import {
	type CompanySearchEntityFragment,
	type MovieSearchEntityFragment,
	type PersonSearchEntityFragment,
	SearchableEntityType,
	type SeriesEpisodeSearchEntityFragment,
	type SeriesSearchEntityFragment,
	type SeriesSeasonSearchEntityFragment,
	type TheaterSearchEntityFragment,
} from "@graphql/types"
import { useDebounce } from "@hooks/use-debounce"

import { Input } from "../../../components/input"
import { useSecurity } from "../../../components/security"
import { Link } from "../../../components/typography"
import { SearchIcon } from "../../../icons/search-icon"
import { useDefaultBrand } from "../preferences"

import clsx from "clsx"
import { useIntl } from "react-intl"
import { Button } from "../../../components/button"
import { ProgressBar } from "../../../components/progress-bar"
import { DEFAULT_SEARCH_OPTIONS } from "../../../config/searchEntities"
import { useHotkey } from "../../../hooks/use-hotkey"
import useInjectGlobalVariables from "../../../hooks/use-inject-global-variables"
import { ClearIcon } from "../../../icons/clear-icon"
import { getGlobalEntityLinkAttrs } from "../../../utils/global-routes"
import CompanyListItem from "./entity-list-item/company-list-item"
import MovieListItem from "./entity-list-item/movie-list-item"
import PersonListItem from "./entity-list-item/person-list-item"
import SeriesEpisodeListItem from "./entity-list-item/series-episode-item-list"
import SeriesListItem from "./entity-list-item/series-list-item"
import SeriesSeasonListItem from "./entity-list-item/series-season-list-item"
import TheaterListItem from "./entity-list-item/theater-list-item"

const DEFAULT_ENTITY_TYPES = [
	SearchableEntityType.Movie,
	SearchableEntityType.Series,
	SearchableEntityType.Company,
	SearchableEntityType.Person,
]

const ENTITY_TYPES_PERMISSIONS: Partial<
	Record<SearchableEntityType, (securityContext: ReturnType<typeof useSecurity>) => boolean>
> = {
	[SearchableEntityType.Movie]: ({ canReadMovies }) => canReadMovies(),
	[SearchableEntityType.Series]: ({ canReadSeries }) => canReadSeries(),
	[SearchableEntityType.SeriesSeason]: ({ canReadSeries }) => canReadSeries(),
	[SearchableEntityType.SeriesEpisode]: ({ canReadSeries }) => canReadSeries(),
	[SearchableEntityType.Company]: ({ canReadCompany }) => canReadCompany(),
	[SearchableEntityType.Person]: ({ canReadPersons }) => canReadPersons(),
}

type SearchEntityResultByGraphQLType = {
	Movie: MovieSearchEntityFragment[]
	Person: PersonSearchEntityFragment[]
	Series: SeriesSearchEntityFragment[]
	Company: CompanySearchEntityFragment[]
	Theater: TheaterSearchEntityFragment[]
	SeriesSeason: SeriesSeasonSearchEntityFragment[]
	SeriesEpisode: SeriesEpisodeSearchEntityFragment[]
}

export type SearchEntity = SearchEntityResultByGraphQLType[keyof SearchEntityResultByGraphQLType][number]

type PossibleSearchEntitiesTypes = keyof SearchEntityResultByGraphQLType

export type ListItemProps = {
	entity: SearchEntity
}
export const ListItem = ({ entity }: ListItemProps) => {
	const defaultBrand = useDefaultBrand()
	const { __typename } = entity

	const className = "hover:no-underline"
	let listItem: ReactNode
	if (__typename === "Movie") listItem = <MovieListItem movie={entity} brand={defaultBrand} />
	else if (__typename === "Series") listItem = <SeriesListItem series={entity} brand={defaultBrand} />
	else if (__typename === "Person") listItem = <PersonListItem person={entity} brand={defaultBrand} />
	else if (__typename === "Company") listItem = <CompanyListItem company={entity} brand={defaultBrand} />
	else if (__typename === "Theater") listItem = <TheaterListItem theater={entity} brand={defaultBrand} />
	else if (__typename === "SeriesSeason") listItem = <SeriesSeasonListItem season={entity} brand={defaultBrand} />
	else if (__typename === "SeriesEpisode") listItem = <SeriesEpisodeListItem episode={entity} brand={defaultBrand} />

	if (!listItem) return null
	return (
		<Link className={className} to={getGlobalEntityLinkAttrs(entity).to}>
			{listItem}
		</Link>
	)
}

type ListProps = {
	index: number
	title: string
	entities: SearchEntity[]
	onItemClick: () => void
}
const List = ({ index, title, entities, onItemClick }: ListProps) => (
	<>
		<h2
			className={clsx("font-bold uppercase mx-4 pb-2 border-b border-gray-200", {
				"pt-2": index > 0,
			})}
		>
			{title}
		</h2>
		<ul className="grid grid-cols-3 py-1 gap-2 mt-4 p-4">
			{entities.map((entity, ix) => (
				<li
					key={`${entity.__typename}-${ix}`}
					className="overflow-hidden hover:bg-gray-200 hover:rounded hover:overflow-visible"
					onClick={onItemClick}
				>
					<ListItem entity={entity} />
				</li>
			))}
		</ul>
	</>
)

const GlobalSearchInput = ({
	entityTypes = DEFAULT_ENTITY_TYPES,
	limitPerEntity = 9,
}: { entityTypes?: SearchableEntityType[]; limitPerEntity?: number }) => {
	const { formatNumber } = useIntl()
	const securityCtx = useSecurity()
	const [show, setShow] = useState(false)
	const [search, setSearch] = useState("")
	const [debouncedSearch, { pending }] = useDebounce(search)
	const inputRef = useRef<HTMLInputElement | null>(null)

	useHotkey(
		"Escape,Control+Shift+F,Control+Shift+f",
		useCallback((_ev, hotkey) => {
			if (hotkey === "Escape") handleClose()
			if (["Control+Shift+f", "Control+Shift+F"].includes(hotkey)) handleOpen()
		}, []),
	)

	const availableEntityTypes = useMemo(
		() =>
			entityTypes.filter((entityType) => {
				if (!ENTITY_TYPES_PERMISSIONS[entityType]) return false
				const perm = ENTITY_TYPES_PERMISSIONS[entityType]
				return perm(securityCtx)
			}),
		[entityTypes, securityCtx],
	)

	const shouldFetch = show && availableEntityTypes.length > 0

	const { data, previousData, loading } = useInjectGlobalVariables(useSearchEntitiesQuery, {
		skip: !shouldFetch,
		variables: {
			search: debouncedSearch || null,
			limit: limitPerEntity,
			offset: 0,
			entityType: availableEntityTypes,
			options: DEFAULT_SEARCH_OPTIONS,
		},
	})

	const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value)
		if (event.target.value) setShow(true)
	}

	const handleOpen = () => {
		setShow(true)
		if (!show) inputRef.current?.setSelectionRange(-1, -1)
		inputRef.current?.focus() // set focus + end of text position
	}

	const handleClose = () => {
		setShow(false)
		inputRef.current?.blur() // remove focus on text input
	}

	const dataTarget = data ?? previousData
	const count = dataTarget?.searchEntities.totalCount ?? 0
	const searchEntities = dataTarget?.searchEntities?.nodes as SearchEntity[]

	const entitiesByIndex = useMemo(() => {
		if (!searchEntities) return undefined

		const results = Object.entries(
			searchEntities?.reduce(
				(acc, entity) => {
					if (!(entity.__typename in acc)) acc[entity.__typename] = []
					;(acc[entity.__typename] as SearchEntity[]).push(entity)
					return acc
				},
				{
					Movie: [],
					Person: [],
					Series: [],
					SeriesSeason: [],
					SeriesEpisode: [],
					Company: [],
					Theater: [],
				} as SearchEntityResultByGraphQLType,
			),
		).reduce(
			(a, [k, items]) => {
				if (items.length > 0) a[k as PossibleSearchEntitiesTypes] = items
				return a
			},
			{} as Record<PossibleSearchEntitiesTypes, SearchEntity[]>,
		)
		return results
	}, [searchEntities])

	if (!availableEntityTypes.length) return null

	return (
		<>
			<div
				className={clsx("z-30", {
					"absolute top-0 left-0 w-full h-full min-h-screen flex flex-col overflow-y-auto bg-black/25": show,
				})}
			>
				<div
					className={clsx({
						"w-full drop-shadow-lg flex-1 min-h-[30%] z-30": show,
					})}
					onClick={handleClose}
				>
					<div
						className={clsx({
							"w-[90%] xl:w-[70%] 2xl:w-[50%] mx-auto bg-white rounded-b-md border-gray-200 ": show,
							"pb-3": count > 0,
						})}
						onClick={(e) => {
							e.stopPropagation() // prevent clicking on content from hiding the whole component
						}}
					>
						{/* Search bar */}
						<div
							className={clsx("h-[56px] z-20 border-b", {
								"sticky top-0": show,
							})}
						>
							<div
								className={clsx("h-full gap-2", {
									"px-2": !show,
									"px-4 flex  bg-white ": show,
								})}
							>
								<div className="h-full w-full flex items-center justify-between gap-1">
									<Input
										Icon={<SearchIcon />}
										ref={inputRef}
										onFocus={() => inputRef.current?.select()}
										className={clsx("h-full w-full border-none pl-2 truncate", {
											"text-lg": show,
											"sm:max-xl2:w-16": !show,
										})}
										value={search}
										onChange={handleSearchChange}
										onClick={handleOpen}
										placeholder="Search across all entities (Ctrl+Shift+F)"
									/>
									{show && (
										<Button className="hover:bg-transparent !pr-0 !pl-0" color="transparent" onClick={handleClose}>
											<ClearIcon size="L" />
										</Button>
									)}
								</div>
							</div>
						</div>
						<ProgressBar
							isIndeterminate={loading || pending}
							color={pending ? "secondary" : "info"}
							className={clsx({
								"opacity-0": !(loading || pending),
							})}
						/>
						{/* Dropdown */}
						{show && entitiesByIndex && (
							<div className="overflow-hidden">
								<h2 className="font-bold mx-4 pb-2 w-full text-center mt-2 ">
									Max of {limitPerEntity} results / {formatNumber(count)}&nbsp;
									{count > 10000 ? "+" : ""}match{count > 1 ? "es" : ""} for entities types:&nbsp;
									{Object.values(availableEntityTypes).join(", ")}...
								</h2>
								{Object.entries(entitiesByIndex).map(([typename, entities], ix) => (
									<List
										index={ix}
										key={`${typename}-${ix}`}
										title={`${typename} (top  ${entities.length} / ${limitPerEntity})`}
										entities={entities}
										onItemClick={handleClose}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default GlobalSearchInput
