import clsx from "clsx"
import type { ComponentType, ReactNode } from "react"
import { createElement } from "react"
import { Link } from "react-router-dom"

import { OpenInNewIcon } from "../icons/open-in-new-icon"
import { type ButtonsStyleProps, useButtonStyle } from "./button"
import type { ButtonColor } from "./button.types"
import type { IconPropsWithoutChildren } from "./icon"

type ButtonLinkProps = {
	to: string
	children: ReactNode
	className?: string
	title?: string
	loading?: boolean
	disabled?: boolean
	color?: ButtonColor
	variant?: ButtonsStyleProps["variant"]
	notRounded?: boolean
	icon?: ComponentType<IconPropsWithoutChildren>
	target?: "_blank"
} & Pick<ButtonsStyleProps, "size">

/**
 * Render a react router navigation link shaped like a button
 */
export function ButtonLink({
	children,
	className,
	loading = false,
	disabled = false,
	color = "info",
	variant,
	size,
	notRounded,
	icon,
	target,
	...rest
}: ButtonLinkProps) {
	const buttonStyle = useButtonStyle({
		size,
		color,
		disabled,
		loading,
		notRounded,
		variant,
		hasIcon: icon !== undefined,
	})

	return (
		<Link
			className={clsx(className, buttonStyle)}
			onClick={(e) => {
				if (disabled) e.preventDefault()
			}}
			target={target}
			{...rest}
		>
			{icon && createElement(icon)}
			{children}
			{target === "_blank" && <OpenInNewIcon />}
		</Link>
	)
}

export function IconButtonLink({
	className,
	loading = false,
	disabled = false,
	color = "info",
	notRounded,
	size,
	...rest
}: ButtonLinkProps) {
	const buttonStyle = useButtonStyle({
		size,
		color,
		disabled,
		loading,
		isIcon: true,
		notRounded,
	})

	return (
		<Link
			className={clsx(className, buttonStyle)}
			onClick={(e) => {
				e.stopPropagation()
				if (disabled) e.preventDefault()
			}}
			{...rest}
		/>
	)
}
