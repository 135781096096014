import type { ReactNode } from "react"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"

import { Unauthorized } from "@components/error"
import { useEvent } from "@hooks/use-event"

import { expect } from "./assert"
import type { JWTPayload } from "./jwt"
import { decodeToken } from "./jwt"
import { LocalStorageKeysEnum, readFromStorage, writeToStorage } from "./storage"

type VoidFunction = () => void

interface Auth {
	session: Session | null
	signIn(token: string, callback: VoidFunction): void
	signOut(callback: VoidFunction): void
	impersonate(token: string | null, callback: VoidFunction): void
}

interface Session {
	expires: number
	data: AuthData
	accessToken: string
	originalToken: string | null
}

/**
 * Represents a JWT permission on a resource.
 * Common types are `brand`, `country` and `locale`.
 */
interface Entitlement {
	type: string
	value: unknown
}

/**
 * Represents the raw JWT payload.
 */
interface AuthPayload extends JWTPayload {
	admin: boolean
	email: string
	scope: string
	entitlements: Entitlement[]
	// TODO: Remove this
	user: {
		id: number
		admin: boolean
		email: string
		permissions: {
			[key: string]: string[]
		}
	}
}

/**
 * Represents the authentication data.
 * The data is derived from the `AuthPayload`.
 */
interface AuthData {
	admin: boolean
	permissions: Set<string>
	entitlements: Entitlement[]
	// TODO: Remove this
	user: AuthPayload["user"]
}

const AuthContext = createContext<Auth | null>(null)

function createSessionFromToken(accessToken: string, originalToken: string | null): Session | null {
	const payload = decodeToken<AuthPayload>(accessToken)
	if (payload) {
		const expires = payload.exp * 1_000
		if (expires < Date.now()) return null

		return {
			expires,
			accessToken,
			originalToken,
			data: {
				admin: payload.admin,
				permissions: new Set(payload.scope.split(" ")),
				entitlements: payload.entitlements,
				user: payload.user,
			},
		}
	}
	return null
}

const ACCESS_TOKEN_KEY = LocalStorageKeysEnum.ACCESS_TOKEN_KEY
const ORIGINAL_TOKEN_KEY = LocalStorageKeysEnum.ORIGINAL_TOKEN_KEY

interface AuthProviderProps {
	children: ReactNode
}

export function AuthProvider(props: AuthProviderProps) {
	const { children } = props

	const [session, setSession] = useState<Session | null>(() => {
		const accessToken = readFromStorage<string>(ACCESS_TOKEN_KEY)
		if (accessToken) {
			const originalToken = readFromStorage<string>(ORIGINAL_TOKEN_KEY)
			return createSessionFromToken(accessToken, originalToken)
		}
		return null
	})

	// Persist the access tokens
	useEffect(() => {
		writeToStorage(ACCESS_TOKEN_KEY, session?.accessToken)
		writeToStorage(ORIGINAL_TOKEN_KEY, session?.originalToken)
	}, [session])

	// Check for session expiration
	useEffect(() => {
		if (!session) return undefined
		// Number of milliseconds until the expiration
		const delta = session.expires - Date.now()
		const timer = setTimeout(() => {
			setSession(null)
		}, delta)
		return () => {
			clearTimeout(timer)
		}
	}, [session])

	const auth: Auth = {
		session,
		signIn(token, callback) {
			const session = createSessionFromToken(token, null)
			if (session) {
				setSession(session)
				callback()
			}
		},
		signOut(callback) {
			setSession(null)
			callback()
		},
		impersonate(token, callback) {
			if (session) {
				if (token) {
					// Create a new session and back up the original access token.
					// If the session is invalid, the user will be signed out.
					setSession(createSessionFromToken(token, session.accessToken))
				} else {
					// When no token is provided, stop the impersonation.
					// If there is no original token or if the token is expired, the user
					// will be signed out.
					setSession(session.originalToken ? createSessionFromToken(session.originalToken, null) : null)
				}
			}
			callback()
		},
	}

	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

interface RequireAuthProps {
	children: ReactNode
	loginPath: string
}

export function RequireAuth(props: RequireAuthProps) {
	const { children, loginPath } = props
	const auth = useAuth()
	const location = useLocation()

	if (!auth.session) {
		// Redirect them to the login page, but save the current location they were
		// trying to go to when they were redirected.
		return <Navigate to={loginPath} state={{ from: location }} replace />
	}

	return <>{children}</>
}

interface RestrictedProps {
	children: ReactNode | ((isGranted: boolean) => ReactNode)
	permission: string | string[]
}

export function Restricted(props: RestrictedProps) {
	const { children, permission } = props
	const isGranted = useIsGranted()

	const result = Array.isArray(permission)
		? permission.every((permission) => isGranted(permission))
		: isGranted(permission)

	if (typeof children === "function") return <>{children(result)}</>

	return result ? <>{children}</> : <Unauthorized />
}

function useAuth(): Auth {
	return expect(useContext(AuthContext), "<AuthProvider> is missing")
}

export function useSession(): Session | null {
	const { session } = useAuth()
	return session
}

type SignInFunction = (token: string) => void

export const useSignIn = (): SignInFunction => {
	const auth = useAuth()
	const navigate = useNavigate()
	const location = useLocation()
	const from = location.state?.from?.pathname ?? "/"
	return useEvent((token) => {
		auth.signIn(token, () => {
			navigate(from, { replace: true })
		})
	})
}

type SignOutFunction = () => void

export const useSignOut = (): SignOutFunction => {
	const auth = useAuth()
	const navigate = useNavigate()
	return useEvent(() => {
		auth.signOut(() => {
			navigate("/")
		})
	})
}

type ImpersonateFunction = (token: string | null) => void

export const useImpersonate = (): ImpersonateFunction => {
	const auth = useAuth()
	const navigate = useNavigate()
	return useEvent((token) => {
		auth.impersonate(token, () => {
			navigate("/")
		})
	})
}

type IsGrantedFunction = (permissions: string) => boolean

export function useIsGranted(): IsGrantedFunction {
	const session = useSession()

	return (permission) => {
		if (!session) return false

		const isLegacy = permission.includes(".*")
		const fPermission = permission.replace(".*", "") // allow compat with legacy permissions syntax

		return (
			session.data.admin ||
			session.data.permissions.has(fPermission) ||
			(isLegacy ? session.data.permissions.has(permission.split(".")[0]) : false)
		)
	}
}

type IsGrantedLegacyFunction = (roleOrPermission: string) => boolean

/**
 * @deprecated favor useIsGranted above
 * */
export function useIsGranted_LEGACY(): IsGrantedLegacyFunction {
	const session = useSession()
	const permissions = useMemo(() => {
		if (!session) return []
		const compressed = Object.entries(session.data.user.permissions)
		return compressed.flatMap(([permission, specifiers]) => specifiers.map((specifier) => `${permission}.${specifier}`))
	}, [session])
	return (roleOrPermission) => {
		if (!session) return false
		const user = session.data.user
		return user.admin || permissions.some((permission) => permission.startsWith(roleOrPermission.replace("*", "")))
	}
}

export const useIsAdmin = () => {
	const session = useSession()
	return session?.data.admin
}
