import clsx from "clsx"
import type { ReactElement } from "react"
import { NavLink } from "react-router-dom"

import { useSecurity } from "@components/security"
import { BankIcon } from "@icons/bank-icon"
import { BoxofficeIcon } from "@icons/boxoffice-icon"
import { CarouselIcon } from "@icons/carousel-icon"
import { CertificateIcon } from "@icons/certificate-icon"
import { CountriesIcon } from "@icons/countries-icon"
import { FestivalIcon } from "@icons/festival-icon"
import { FilmStripIcon } from "@icons/filmstrip-icon"
import { GraphIcon } from "@icons/graph-icon"
import { ImportExportIcon } from "@icons/import-export-icon"
import { LocalActivityIcon } from "@icons/local-activity-icon"
import { LocaleIcon } from "@icons/locale-icon"
import { ManIcon } from "@icons/man-icon"
import { MatchingIcon } from "@icons/matching-icon"
import { MovieIcon } from "@icons/movie-icon"
import { NewspaperVariantOutlineIcon } from "@icons/newspaper-variant-outline-icon"
import { OpeSpeIcon } from "@icons/ope-spe-icon"
import { PhotoLibraryIcon } from "@icons/photo-library-icon"
import { ProxyIcon } from "@icons/proxy-icon"
import { ReviewsIcon } from "@icons/reviews-icon"
import { RuleIcon } from "@icons/rule-icon"
import { ScreenIcon } from "@icons/screen-icon"
import { TagsIcon } from "@icons/tags-icon"
import { TriviaIcon } from "@icons/trivia-icon"
import { UserIcon } from "@icons/user-icon"
import { VideoLibraryIcon } from "@icons/video-library-icon"
import { LinkIcon } from "../../icons/link-icon"
import { WidgetIcon } from "../../icons/widget-icon"

interface MenuProps {
	showMenu: boolean
}

interface SubNavProps {
	items?: NavItem[]
}

interface MenuLinkProps {
	link?: string
	icon?: ReactElement
	label: string
	subNavs?: NavItem[]
}

interface NavItem {
	icon?: ReactElement
	label: string
	link?: string
	permissionFn?: (securityContext: ReturnType<typeof useSecurity>) => boolean
	subNavs?: NavItem[]
}

const NAV_ITEMS: NavItem[] = [
	{
		label: "Entities",
		subNavs: [
			{
				icon: <MovieIcon />,
				label: "Movies",
				link: "/movies/",
				permissionFn: ({ canReadMovies }) => canReadMovies(),
			},
			{
				icon: <ScreenIcon />,
				label: "Series",
				link: "/series/",
				permissionFn: ({ canReadSeries }) => canReadSeries(),
			},
			{
				icon: <ManIcon />,
				label: "Persons",
				link: "/persons/",
				permissionFn: ({ canReadPersons }) => canReadPersons(),
			},
			{
				icon: <BankIcon />,
				label: "Companies",
				link: "/companies/",
				permissionFn: ({ canReadCompany }) => canReadCompany(),
			},
			{
				icon: <FilmStripIcon />,
				label: "Theaters",
				link: "/theaters/",
				permissionFn: ({ canReadTheater }) => canReadTheater(),
			},
			{
				icon: <FestivalIcon />,
				label: "Festivals",
				link: "/festivals/",
				permissionFn: ({ canReadFestival }) => canReadFestival(),
			},
		],
	},
	{
		label: "Editorial",
		subNavs: [
			{
				icon: <NewspaperVariantOutlineIcon />,
				label: "News",
				link: "/news/",
				permissionFn: ({ canReadNews }) => canReadNews(),
			},
			{
				icon: <CarouselIcon />,
				label: "Carousels",
				permissionFn: ({ canReadCarousels }) => canReadCarousels(),
				link: "/carousels",
				subNavs: [
					{
						label: "Slides",
						link: "/carousels/slides",
					},
				],
			},
			{
				icon: <OpeSpeIcon />,
				label: "Special operations",
				link: "/special-operations/",
				permissionFn: ({ canReadSpecialOperations }) => canReadSpecialOperations(),
			},
			{
				icon: <TagsIcon />,
				label: "Tags",
				link: "/tags/",
				permissionFn: ({ canReadSeoTags }) => canReadSeoTags(),
			},
			{
				icon: <TriviaIcon />,
				label: "Trivia",
				link: "/trivias/",
				permissionFn: ({ canReadTrivia }) => canReadTrivia(),
			},
			{
				icon: <ReviewsIcon />,
				label: "Reviews",
				subNavs: [
					{
						label: "Press",
						link: "/reviews/press/",
						permissionFn: ({ canReadPressReviews }) => canReadPressReviews(),
					},
					{
						label: "Editorial",
						link: "/reviews/editorial/",
						permissionFn: ({ canEditEditorialReviews }) => canEditEditorialReviews(),
					},
				],
			},
			{
				icon: <UserIcon />,
				label: "Authors",
				link: "/authors/",
				permissionFn: ({ canReadAuthor }) => canReadAuthor(),
			},
		],
	},
	{
		label: "Media",
		subNavs: [
			{
				icon: <VideoLibraryIcon />,
				label: "Videos",
				link: "/videos/",
				permissionFn: ({ canEditVideo }) => canEditVideo(),
			},
			{
				icon: <PhotoLibraryIcon />,
				label: "Images",
				link: "/images/",
				permissionFn: ({ canEditImage }) => canEditImage(),
			},
		],
	},
	{
		label: "Social",
		permissionFn: ({ canEditSocialUsers, canEditSocialReviews }) => canEditSocialUsers() || canEditSocialReviews(),
		subNavs: [
			{
				icon: <UserIcon />,
				label: "Users",
				link: "/social/users",
				permissionFn: ({ canEditSocialUsers }) => canEditSocialUsers(),
			},
			{
				icon: <ReviewsIcon />,
				label: "Reviews",
				link: "/social/reviews",
				permissionFn: ({ canEditSocialReviews }) => canEditSocialReviews(),
			},
		],
	},
	{
		label: "Showtimes",
		subNavs: [
			{
				icon: <LocalActivityIcon />,
				label: "Manual entry",
				link: "/clocks/",
				permissionFn: ({ canReadShowtimes }) => canReadShowtimes(),
			},
			{
				icon: <ImportExportIcon />,
				label: "Imports",
				subNavs: [
					{
						label: "Configurations",
						link: "/imports/configurations/",
						permissionFn: ({ canReadKhronos }) => canReadKhronos(),
					},
					{
						label: "Runs",
						link: "/imports/runs/",
						permissionFn: ({ canReadKhronos }) => canReadKhronos(),
					},
					{
						label: "Showtimes operations",
						link: "/imports/showtimes/",
						permissionFn: ({ canReadKhronos }) => canReadKhronos(),
					},
					{
						label: "Crawlers",
						link: "/imports/crawlers/",
						permissionFn: ({ canReadKhronos }) => canReadKhronos(),
					},
				],
			},
		],
	},
	{
		label: "Tools",
		subNavs: [
			{
				icon: <MatchingIcon />,
				label: "Content Matching",
				subNavs: [
					{
						label: "Products",
						link: "/matching-tool/products",
						permissionFn: ({ canReadMatchingTool }) => canReadMatchingTool(),
					},
					{
						label: "Showtimes",
						link: "/matching-tool/showtimes/entity",
						permissionFn: ({ canReadMatchingToolShowtimes }) => canReadMatchingToolShowtimes(),
					},
				],
			},
			{
				icon: <RuleIcon />,
				label: "Duplicates",
				link: "/duplicates/",
				permissionFn: ({ canReadDuplicates }) => canReadDuplicates(),
			},
			{
				icon: <BoxofficeIcon />,
				label: "Boxoffice Entry",
				link: "/boxoffices/",
				permissionFn: ({ canReadBoxOffice }) => canReadBoxOffice(),
			},
			{
				icon: <CountriesIcon />,
				label: "Countries",
				link: "/countries/",
				permissionFn: ({ canReadCountry }) => canReadCountry(),
			},
			{
				icon: <LinkIcon />,
				label: "Jump Platform",
				link: "/jump-platform",
				permissionFn: ({ canEditJumpLinks }) => canEditJumpLinks(),
			},
		],
	},
	{
		label: "Administration",
		subNavs: [
			{
				icon: <UserIcon />,
				label: "Access Management",
				permissionFn: ({ canReadSecurity }) => canReadSecurity(),
				subNavs: [
					{
						label: "Users",
						link: "/security/users/",
					},
					{
						label: "Teams",
						link: "/security/teams/",
					},
					{
						label: "Presets",
						link: "/security/presets/",
					},
				],
			},
			{
				icon: <LocaleIcon />,
				label: "Locales",
				link: "/locales/",
				permissionFn: ({ canReadLocales }) => canReadLocales(),
			},
			{
				icon: <CertificateIcon />,
				label: "Certificates/Ratings",
				link: "/certificates/",
				permissionFn: ({ canReadRatings }) => canReadRatings(),
			},
			{
				icon: <WidgetIcon />,
				label: "Widgets",
				link: "/widgets/",
				permissionFn: ({ canReadWidget }) => canReadWidget(),
			},
			{
				icon: <GraphIcon />,
				label: "Graph API",
				permissionFn: ({ canReadACGraphAPI }) => canReadACGraphAPI(),
				subNavs: [
					{
						label: "Applications",
						link: "/graph/applications",
					},
					{
						label: "Tokens",
						link: "/graph/tokens",
					},
				],
			},
			{
				icon: <ProxyIcon />,
				label: "Graph Proxy",
				permissionFn: ({ canReadACGraphAPI }) => canReadACGraphAPI(),
				subNavs: [
					{
						label: "Customers",
						link: "/proxy/customers",
					},
					{
						label: "Queries",
						link: "/proxy/queries",
					},
					{
						label: "Scopes",
						link: "/proxy/scopes",
					},
				],
			},
		],
	},
]

function MenuLink({ icon, link, label, subNavs = [] }: MenuLinkProps) {
	const labelElement = (
		<span className="inline-flex items-center gap-3">
			{icon}
			{label}
		</span>
	)

	return (
		<li className="text-gray-600">
			{link ? (
				<NavLink
					end
					to={link}
					className={({ isActive }) =>
						clsx("block hover:text-black", {
							"text-gray-800 border-r-4 border-red-600": isActive,
						})
					}
				>
					{labelElement}
				</NavLink>
			) : (
				<div className="font-semibold">{labelElement}</div>
			)}
			{subNavs.length > 0 && (
				<ul className="ml-2 pl-5 text-sm border-l border-gray-400 leading-6">
					<SubNav items={subNavs} />
				</ul>
			)}
		</li>
	)
}

function SubNav({ items = [] }: SubNavProps) {
	const allowedItems = removeNotGranted(items, useSecurity())
	if (allowedItems.length === 0) return null

	return (
		<>
			{allowedItems.map((item, subIndex) => (
				<MenuLink key={subIndex} label={item.label} link={item.link} icon={item.icon} subNavs={item.subNavs} />
			))}
		</>
	)
}

type SecurityContext = ReturnType<typeof useSecurity>

const removeNotGranted = (items: NavItem[], securityCtx: SecurityContext): NavItem[] => {
	const filteredItems = items.filter((item) => {
		let showSubNavs = false
		if (item.subNavs) {
			const real = removeNotGranted(item.subNavs, securityCtx)
			showSubNavs = real.length !== 0
		}

		let hasAccess = true
		if (item.permissionFn) hasAccess = item.permissionFn(securityCtx)

		return hasAccess && (item.link !== undefined ? true : showSubNavs)
	})

	return filteredItems
}

export function MainMenu({ showMenu }: MenuProps) {
	return (
		<nav
			className={clsx(
				"animate-quick-appear",
				"fixed bottom-0 top-14 py-4",
				"bg-white border-r border-gray-200",
				"overflow-auto overscroll-none",
				"hide-scrollbar",
				{
					"transform -translate-x-60": !showMenu,
				},
			)}
			style={{ zIndex: 1, width: "calc(15rem - 1px)" }} // strange issue with z-1 from tailwind & overrides + wdth on some zoom levels
		>
			<ul className="pl-4 leading-10">
				<SubNav items={NAV_ITEMS} />
			</ul>
		</nav>
	)
}
