import type { FieldPolicy } from "@apollo/client"
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client"
import { RetryLink } from "@apollo/client/link/retry"
import type { ReactNode } from "react"
import { useMemo } from "react"
import { IntlProvider } from "react-intl"

import { Unavailable } from "@components/error"
import { ErrorBoundary } from "@components/error-boundary"
import { AuthProvider, useSession } from "@utils/auth"

import { lightTheme } from "@allocine/ui-components"
import { ThemeProvider } from "@mui/material"
import { type OperationDefinitionNode, OperationTypeNode } from "graphql"
import { BrowserCompatibilityChecker } from "./browser-compatibility-checker"
import { NotificationProvider } from "./components/notification"
import ScrollToHashElement from "./components/scrollToHashElement"
import { possibleTypes } from "./graphql/possible-types.json"
import { App } from "./pages/app"
import { ViewerProvider } from "./pages/shared/viewer"

const MAX_REQ_RETRIES = 30

type ApolloHttpError = Error & {
	statusCode: number
}

// biome-ignore lint/suspicious/noExplicitAny: large typeguard
const isApolloHttpError = (v: any): v is ApolloHttpError =>
	v && v instanceof Error && "statusCode" in v && typeof v.statusCode === "number"

const SecurityAwareApolloProvider = ({ children }: { children: ReactNode }) => {
	const session = useSession()
	const client = useMemo(() => {
		const replace: FieldPolicy = {
			merge: (_, incoming) => incoming,
		}

		return new ApolloClient({
			cache: new InMemoryCache({
				possibleTypes,
				typePolicies: {
					Theater: {
						fields: {
							location: replace,
						},
					},
					Carousel: {
						fields: {
							slides: replace,
						},
					},
					Slide: {
						fields: {
							parents: replace,
						},
					},
					News: {
						fields: {
							authors: replace,
							publications: replace,
							pages: replace,
						},
					},
					User: {
						fields: {
							rights: replace,
						},
					},
					Query: {
						fields: {
							wysiwyg: replace,
						},
					},
				},
			}),
			link: ApolloLink.from([
				new RetryLink({
					delay: {
						initial: 150,
						max: 30 * 1000,
						jitter: true,
					},
					attempts: (count, operation, error) => {
						const operationType = (
							operation.query.definitions.find((def) => def.kind === "OperationDefinition") as OperationDefinitionNode
						).operation
						if (operationType !== OperationTypeNode.MUTATION && isApolloHttpError(error) && error.statusCode < 500)
							return false // do not retry 400 errors or below on queries
						if (Boolean(error) && operationType !== OperationTypeNode.MUTATION && count < MAX_REQ_RETRIES) return true // only retry queries
						return false // do not retry mutations
					},
				}),
				new ApolloLink((operation, forward) => {
					operation.setContext({
						headers: {
							// dynamically set authorization header
							Authorization: `Bearer ${session?.accessToken}`,
						},
						fetchOptions: {
							// send the actual referrer instead of the origin
							referrerPolicy: "unsafe-url",
						},
					})
					return forward(operation)
				}),
				new HttpLink({
					uri: __ENV__.GRAPH_BASE_URL,
				}),
			]),
			defaultOptions: {
				watchQuery: {
					fetchPolicy: "network-only",
				},
			},
		})
	}, [session])

	return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const Main = () => (
	<BrowserCompatibilityChecker>
		<ThemeProvider theme={lightTheme}>
			<ScrollToHashElement />
			<ErrorBoundary fallbackComponent={Unavailable}>
				<IntlProvider
					locale="en"
					formats={{
						date: {
							"date-time": {
								year: "numeric",
								month: "numeric",
								day: "numeric",
								hour: "numeric",
								minute: "numeric",
								second: "numeric",
								hour12: false,
							},
						},
					}}
				>
					<AuthProvider>
						<SecurityAwareApolloProvider>
							<ViewerProvider>
								<NotificationProvider maxStacked={3} position="top-right" closeAfterMs={3000}>
									<App />
								</NotificationProvider>
							</ViewerProvider>
						</SecurityAwareApolloProvider>
					</AuthProvider>
				</IntlProvider>
			</ErrorBoundary>
		</ThemeProvider>
	</BrowserCompatibilityChecker>
)
