import clsx from "clsx"
import { type ReactNode, type Ref, useState } from "react"
import type { Props as ReactSelectProps } from "react-select"
import ReactSelect from "react-select"
import ReactSelectCreatable from "react-select/creatable"

import { getEndOfTag3d } from "../utils/tag"
import styles from "./select.module.css"

export interface SelectProps<Option, IsMulti extends boolean = false> extends ReactSelectProps<Option, IsMulti> {
	invalid?: boolean
	// biome-ignore lint/suspicious/noExplicitAny: too variadic
	selectRef?: Ref<any>
	allowCreate?: boolean
}

// Can't retrieve props signature on complex generic
export type SelectCreatableProps<T> = {
	onCreateOption?: (inputValue: string) => void
	getNewOptionData?: (inputValue: string, inputLabel: ReactNode) => T
	formatCreateLabel?: (inputValue: string) => ReactNode
	createOptionPosition?: "first" | "last"
	isValidNewOption?: (inputValue: string) => boolean
}

// biome-ignore lint/suspicious/noExplicitAny: too many errors there
export interface SelectOption<T = any> {
	value: T
	label: string
}

export function createOption<T>(value: T, label?: string): SelectOption<T> {
	return {
		value,
		label: label ?? String(value),
	}
}

export const createTag3dOption = (value: string) => ({
	value,
	label: getEndOfTag3d(value),
})

export function findOptionValue<T>(
	options: SelectOption<T>[],
	value: T | null | undefined,
): SelectOption<T> | undefined {
	return value != null ? options.find((option) => option.value === value) : undefined
}

export const filterOptionCaseInsensitve = (option: SelectOption, input: string) =>
	option.label.trim().toLowerCase().includes(input.trim().toLowerCase())

export function getOptionValue<T>(option?: SelectOption<T>): T | null {
	return option ? option.value : null
}

export function Select<Option, IsMulti extends boolean = false>(
	props: SelectProps<Option, IsMulti> & SelectCreatableProps<Option>,
) {
	const {
		allowCreate = false,
		className,
		invalid = false,
		menuPortalTarget = document.body,
		selectRef,
		defaultInputValue,
		value,
		onInputChange,
		...rest
	} = props
	const [inputValue, setInputValue] = useState<string>(props.defaultInputValue ?? props.inputValue ?? "")
	const SelectComponent = allowCreate ? ReactSelectCreatable : ReactSelect
	return (
		<SelectComponent
			menuPosition="fixed"
			ref={selectRef}
			defaultInputValue={defaultInputValue}
			inputValue={inputValue}
			value={value}
			onInputChange={(value, action) => {
				onInputChange?.(value, action)
				// Must be used to keep input value after loosing focus
				if (action.action !== "input-blur" && action.action !== "menu-close") setInputValue(value)
			}}
			menuIsOpen={!!defaultInputValue && !value ? true : undefined}
			classNamePrefix="react-select"
			menuPortalTarget={menuPortalTarget}
			styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
			className={clsx(className, styles.select, {
				[styles.select__invalid]: invalid,
			})}
			{...rest}
		/>
	)
}
