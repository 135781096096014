import {
	CompanyOrderField,
	MovieOrderField,
	MovieSearchAlgorithm,
	OrderDirection,
	PersonOrderField,
	type SearchOptionsInput,
	SearchableEntityType,
	SeriesEpisodeOrderField,
	SeriesOrderField,
	SeriesSeasonOrderField,
	TheaterOrderField,
} from "../graphql/types"

export const DEFAULT_SEARCH_OPTIONS: SearchOptionsInput = {
	[SearchableEntityType.Movie]: {
		algorithm: MovieSearchAlgorithm.ReleasesFirst,
		orderBy: {
			field: MovieOrderField.Score,
			direction: OrderDirection.Desc,
		},
	},
	[SearchableEntityType.Person]: {
		orderBy: {
			field: PersonOrderField.Score,
			direction: OrderDirection.Desc,
		},
	},
	[SearchableEntityType.Series]: {
		orderBy: {
			field: SeriesOrderField.Score,
			direction: OrderDirection.Desc,
		},
	},
	[SearchableEntityType.SeriesSeason]: {
		orderBy: {
			field: SeriesSeasonOrderField.Score,
			direction: OrderDirection.Desc,
		},
	},
	[SearchableEntityType.SeriesEpisode]: {
		orderBy: {
			field: SeriesEpisodeOrderField.Score,
			direction: OrderDirection.Desc,
		},
	},
	[SearchableEntityType.Company]: {
		orderBy: {
			field: CompanyOrderField.Score,
			direction: OrderDirection.Desc,
		},
	},
	[SearchableEntityType.Theater]: {
		orderBy: {
			field: TheaterOrderField.Score,
			direction: OrderDirection.Desc,
		},
	},
}
