import { type TooltipProps, Tooltip as _Tooltip, styled, tooltipClasses } from "@mui/material"

export type TooltipPropsVariant = "w-lg" | "w-xl"

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
	<_Tooltip {...props} classes={{ popper: className }} />
))({
	variants: [
		{
			props: { variant: "w-lg" },
			style: {
				[`& .${tooltipClasses.tooltip}`]: {
					maxWidth: "30vw",
				},
			},
		},
		{
			props: { variant: "w-xl" },
			style: {
				[`& .${tooltipClasses.tooltip}`]: {
					maxWidth: "50vw",
				},
			},
		},
	],
})
