import { styled, typographyClasses } from "@mui/material"
import _FormControlLabel from "@mui/material/FormControlLabel"

export const FormControlLabel = styled(_FormControlLabel)(({ theme }) => ({
	color: theme.palette.grey[800],
	fontSize: theme.typography.fontSize,
	[`& .${typographyClasses.root}`]: {
		fontSize: theme.typography.fontSize,
	},
}))
