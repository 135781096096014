import { format } from "date-fns"

export type DatePickerRevampedSupportedLocale = "fr" | "fr-FR" | "es" | "es-MX" | "en-US" | "en"

export const defaultLocale = "en"

export enum ISODateFormats {
	DATE = "yyyy-MM-dd",
	DATETIME_HOUR_MIN_SECONDS = "yyyy-MM-dd'T'HH:mm:ss",
	TIME_HOUR_MIN_SECONDS = "HH:mm:ss",
}

export enum DateFormatsRevampedEnum {
	DATE = "yyyy-MM-dd",
	DATETIME_HOUR_MIN = "yyyy-MM-dd HH:mm",
	PARTIAL_DATE_YEAR_MONTH_DAY = "yyyy-MM-dd",
	PARTIAL_DATE_YEAR_MONTH = "yyyy-MM",
	PARTIAL_DATE_YEAR = "yyyy",
	TIME_HOUR_MIN_SECONDS = "HH:mm:ss",
}

export type DateFormat =
	| DateFormatsRevampedEnum.DATE
	| DateFormatsRevampedEnum.DATETIME_HOUR_MIN
	| DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH_DAY
	| DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH
	| DateFormatsRevampedEnum.PARTIAL_DATE_YEAR
	| DateFormatsRevampedEnum.TIME_HOUR_MIN_SECONDS

export const latinDateFormatLocalized: Record<DateFormatsRevampedEnum, string> = {
	[DateFormatsRevampedEnum.DATE]: "dd/MM/yyyy",
	[DateFormatsRevampedEnum.DATETIME_HOUR_MIN]: "dd/MM/yyyy HH:mm",
	[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH]: "MM/yyyy",
	[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR]: "yyyy",
	[DateFormatsRevampedEnum.TIME_HOUR_MIN_SECONDS]: DateFormatsRevampedEnum.TIME_HOUR_MIN_SECONDS,
}
export const allLatinDateFormats = Object.values(latinDateFormatLocalized)

export const enDateFormatLocalized: Record<DateFormatsRevampedEnum, string> = {
	[DateFormatsRevampedEnum.DATE]: DateFormatsRevampedEnum.DATE,
	[DateFormatsRevampedEnum.DATETIME_HOUR_MIN]: DateFormatsRevampedEnum.DATETIME_HOUR_MIN,
	[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH]: DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH,
	[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR]: DateFormatsRevampedEnum.PARTIAL_DATE_YEAR,
	[DateFormatsRevampedEnum.TIME_HOUR_MIN_SECONDS]: DateFormatsRevampedEnum.TIME_HOUR_MIN_SECONDS,
}
export const allEnDateFormats = Object.values(enDateFormatLocalized)

export const dateFormatLocalized: Record<DatePickerRevampedSupportedLocale, Record<DateFormat, string | DateFormat>> = {
	fr: latinDateFormatLocalized,
	"fr-FR": latinDateFormatLocalized,
	es: latinDateFormatLocalized,
	"es-MX": latinDateFormatLocalized,
	[defaultLocale]: enDateFormatLocalized,
	"en-US": enDateFormatLocalized,
}

export const PARTIAL_DATE_YEAR_FORMATS = [
	DateFormatsRevampedEnum.PARTIAL_DATE_YEAR,
	latinDateFormatLocalized[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR],
]
export const PARTIAL_DATE_YEAR_MONTH_FORMATS = [
	DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH,
	latinDateFormatLocalized[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH],
]

export const latinDateFormatLocalizedPlaceholders = {
	[DateFormatsRevampedEnum.DATE]: "jj/mm/aaaa",
	[DateFormatsRevampedEnum.DATETIME_HOUR_MIN]: "jj/mm/aaaa hh:mm",
	[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH]: "mm/aaaa",
	[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR]: "aaaa",
	[DateFormatsRevampedEnum.TIME_HOUR_MIN_SECONDS]: "hh:mm:ss",
}
export const enDateFormatLocalizedPlaceholders = {
	[DateFormatsRevampedEnum.DATE]: "yyyy-mm-dd",
	[DateFormatsRevampedEnum.DATETIME_HOUR_MIN]: "yyyy-mm-dd hh:mm",
	[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR_MONTH]: "yyyy-mm",
	[DateFormatsRevampedEnum.PARTIAL_DATE_YEAR]: "yyyy",
	[DateFormatsRevampedEnum.TIME_HOUR_MIN_SECONDS]: "hh:mm:ss",
}

export const dateFormatLocalizedPlaceholders: Record<DatePickerRevampedSupportedLocale, Record<DateFormat, string>> = {
	fr: latinDateFormatLocalizedPlaceholders,
	"fr-FR": latinDateFormatLocalizedPlaceholders,
	es: latinDateFormatLocalizedPlaceholders,
	"es-MX": latinDateFormatLocalizedPlaceholders,
	[defaultLocale]: enDateFormatLocalizedPlaceholders,
	"en-US": enDateFormatLocalizedPlaceholders,
}

export const filterTimeAfter =
	(refDate: Date | string | null | undefined, { supEquals = false } = {}) =>
	(time: string) => {
		if (!refDate) return true
		let d = refDate
		if (typeof d === "string") d = new Date(refDate)
		const selectedDate = new Date(time)
		return supEquals ? d.getTime() >= selectedDate.getTime() : d.getTime() > selectedDate.getTime()
	}

export const filterTimeBefore =
	(refDate: Date | string | null | undefined, { infEquals = false } = {}) =>
	(time: string) => {
		if (!refDate) return true
		let d = refDate
		if (typeof d === "string") d = new Date(refDate)
		const selectedDate = new Date(time)
		return infEquals ? d.getTime() <= selectedDate.getTime() : d.getTime() < selectedDate.getTime()
	}

export const partialDateTransformer =
	(dateFormat: DateFormatsRevampedEnum | string = DateFormatsRevampedEnum.DATE) =>
	(d: Date | string | null | undefined) => {
		if (d instanceof Date) return format(d, dateFormat)
		return d
	}

export const jsDateTransformer = (d: Date | string | null | undefined) => {
	if (d instanceof Date) return d
	if (typeof d === "string") return new Date(d)
	return d
}

export const dateTransformer = (d: Date | string | null | undefined) => {
	if (!d) return d
	return format(new Date(d), DateFormatsRevampedEnum.DATE)
}

export const isoTransformer = (d: Date | string | null | undefined) => {
	if (!d) return d
	return new Date(d).toISOString()
}

export const isoWithoutTZTransformer = (d: Date | string | null | undefined) => {
	if (!d) return d
	return format(new Date(d), ISODateFormats.DATETIME_HOUR_MIN_SECONDS)
}

export const timeWithoutTZTransformer = (d: Date | string | null | undefined) => {
	if (!d) return d
	return format(new Date(d), ISODateFormats.TIME_HOUR_MIN_SECONDS)
}
