import { useIsAdmin, useIsGranted, useIsGranted_LEGACY } from "@utils/auth"
import { useCallback, useMemo } from "react"
import { PermissionTarget } from "../config/permissionTargets"
import type { BrandOption } from "../pages/shared/components/select-brand"

export type SecurityBrandArgs = { brand?: Pick<BrandOption, "id" | "ref"> | null }

export type SeoTagLinkableEntityType =
	| "Person"
	| "Movie"
	| "Series"
	| "SeriesSeason"
	| "SeriesEpisode"
	| "News"
	| "Company"
	| "Slide"
	| "Theater"

export function useSecurity() {
	const isGranted = useIsGranted()
	/**
	 * @deprecated use isGranted instead
	 */
	const isGrantedLegacy = useIsGranted_LEGACY()
	const isAdminFromHook = useIsAdmin()

	const isComputedAdmin = useMemo(() => isAdminFromHook ?? false, [isAdminFromHook])
	const isAdmin = useCallback(() => isComputedAdmin, [isComputedAdmin])
	const canDeleteEntities = useCallback(() => isGranted(PermissionTarget.ENTITY_DELETION), [isGranted])
	const canDeleteSeries = useCallback(
		() => canDeleteEntities() && isGranted(PermissionTarget.SERIES),
		[isGranted, canDeleteEntities],
	)
	const canEditSeoData = useCallback(
		() => isGranted(PermissionTarget.ENTITY_SEO_DATA) || isGrantedLegacy("seo.*"),
		[isGrantedLegacy, isGranted],
	)

	const canEditSocialReviews = useCallback(
		() => isGranted(PermissionTarget.SOCIAL_USER_REVIEW) || isGrantedLegacy("social.*"),
		[isGrantedLegacy, isGranted],
	)
	const canEditSocialUsers = useCallback(
		() => isGranted(PermissionTarget.SOCIAL_USER) || isGrantedLegacy("social.*"),
		[isGrantedLegacy, isGranted],
	)

	type CanEditEntityOnBrandEntityType = "Person" | "Movie" | "Series" | "SeriesSeason" | "SeriesEpisode"
	const canEditEntityOnBrand = useCallback(
		({
			brand,
			entityType,
		}: SecurityBrandArgs & {
			entityType: CanEditEntityOnBrandEntityType
		}) => {
			if (!brand) return false // event admins or seo managers need to specify a brand at this level
			if (isGranted(PermissionTarget.ENTITY_SEO_DATA) || isGrantedLegacy("seo.*")) return true
			if (isGranted(PermissionTarget.ENTITY_BRAND_DATA)) return true
			if (entityType === "Person") return isGrantedLegacy(`person.brand.${brand.ref}`)
			if (entityType === "Movie") return isGrantedLegacy(`movie.brand.${brand.ref}`)
			if (entityType === "Series") return isGrantedLegacy(`series.brand.${brand.ref}`)
			if (entityType === "SeriesSeason") return isGrantedLegacy(`series.brand.${brand.ref}`)
			if (entityType === "SeriesEpisode") return isGrantedLegacy(`series.brand.${brand.ref}`)
			return false
		},
		[isGrantedLegacy, isGranted],
	)
	const canEditSeries = useCallback(
		() => isGranted(PermissionTarget.SERIES) || isGrantedLegacy("series.*"),
		[isGrantedLegacy, isGranted],
	)
	const canEditPersons = useCallback(
		() => isGrantedLegacy(`${PermissionTarget.PERSON}.*`) || isGranted(PermissionTarget.PERSON),
		[isGrantedLegacy, isGranted],
	)

	const canEditMovies = useCallback(
		() => isGranted(PermissionTarget.MOVIE) || isGrantedLegacy("movie.*"),
		[isGrantedLegacy, isGranted],
	)

	const canReadSeoTags = useCallback(
		() =>
			isGrantedLegacy("seo.*") ||
			isGrantedLegacy("tag.brand.*") ||
			[
				PermissionTarget.ENTITY_SEO_DATA,
				PermissionTarget.BRAND_TAG,
				PermissionTarget.NEWS_LINK_TAGS,
				PermissionTarget.MOVIE_LINK_TAGS,
				PermissionTarget.SERIES_LINK_TAGS,
				PermissionTarget.PERSON_LINK_TAGS,
			].some(isGranted),
		[isGranted, isGrantedLegacy],
	)
	const canEditSeoTags = useCallback(
		() => isGrantedLegacy("seo.*") || [PermissionTarget.ENTITY_SEO_DATA, PermissionTarget.BRAND_TAG].some(isGranted),
		[isGranted, isGrantedLegacy],
	)
	const canEditSeoTagOnBrand = useCallback(
		({
			brand,
			entityType,
		}: SecurityBrandArgs & {
			entityType?: SeoTagLinkableEntityType
		} = {}) => {
			if (canEditSeoTags()) return true
			const brandRef = brand?.ref ?? "*"
			if (isGrantedLegacy(`tag.brand.${brandRef}`)) return true
			if (!entityType) return false

			if (entityType === "Person") return isGrantedLegacy(`person.brand.${brandRef}`)
			if (entityType === "Movie") return isGrantedLegacy(`movie.brand.${brandRef}`)
			if (entityType === "Series") return isGrantedLegacy(`series.brand.${brandRef}`)
			if (entityType === "Company") return isGrantedLegacy(`company.brand.${brandRef}`)
			if (entityType === "Theater") return isGrantedLegacy(`theater.brand.${brandRef}`)
			return false
		},
		[isGrantedLegacy, canEditSeoTags],
	)

	const canEditLinkSeoTagToEntity = useCallback(
		({
			entityType,
			brand,
		}: SecurityBrandArgs & {
			entityType?: SeoTagLinkableEntityType
		} = {}) => {
			if (canEditSeoTags()) return true
			if (canEditSeoTagOnBrand({ brand })) return true

			if (!entityType)
				return [PermissionTarget.NEWS_LINK_TAGS, PermissionTarget.MOVIE_LINK_TAGS, PermissionTarget.SERIES_LINK_TAGS].some(
					isGranted,
				)

			const authorizedTypes: SeoTagLinkableEntityType[] = ["News", "Movie", "Series", "SeriesSeason", "SeriesEpisode"]
			if (!authorizedTypes.includes(entityType)) return false

			if (entityType === "News") return isGranted(PermissionTarget.NEWS_LINK_TAGS)
			if (entityType === "Movie") return isGranted(PermissionTarget.MOVIE_LINK_TAGS)
			if (authorizedTypes.includes(entityType)) return isGranted(PermissionTarget.SERIES_LINK_TAGS)

			return false
		},
		[isGranted, canEditSeoTags, canEditSeoTagOnBrand],
	)
	const canEditLinkSeoTagToPerson = useCallback(
		({
			brand,
		}: {
			brand?: BrandOption | null
		} = {}) => {
			if (canEditSeoTags()) return true
			if (canEditSeoTagOnBrand({ brand })) return true
			return isGranted(PermissionTarget.PERSON_LINK_TAGS)
		},
		[isGranted, canEditSeoTags, canEditSeoTagOnBrand],
	)
	const canEditLinkSeoTagToMovie = useCallback(
		({ brand }: SecurityBrandArgs = {}) => canEditLinkSeoTagToEntity({ brand, entityType: "Movie" }),
		[canEditLinkSeoTagToEntity],
	)

	const canEditLinkSeoTagToSeries = useCallback(
		({ brand }: SecurityBrandArgs = {}) => canEditLinkSeoTagToEntity({ brand, entityType: "Series" }),
		[canEditLinkSeoTagToEntity],
	)

	const canEditLinkSeoTagToNews = useCallback(
		({ brand }: SecurityBrandArgs = {}) => canEditLinkSeoTagToEntity({ brand, entityType: "News" }),
		[canEditLinkSeoTagToEntity],
	)

	const canEditAuthor = useCallback(() => isGrantedLegacy("author.*"), [isGrantedLegacy])

	const canEditBoxOffice = useCallback(() => isGrantedLegacy("boxoffice.*"), [isGrantedLegacy])

	const canEditCompany = useCallback(() => isGrantedLegacy("company.*"), [isGrantedLegacy])

	const canEditSpecialOperations = useCallback(() => isGrantedLegacy("specialOperation.*"), [isGrantedLegacy])

	const canEditKhronos = useCallback(() => isGrantedLegacy("khronos.*"), [isGrantedLegacy])

	const canEditMatchingTool = useCallback(() => isGrantedLegacy("matching_tool.*"), [isGrantedLegacy])

	const canEditMatchingToolShowtimes = useCallback(() => isGrantedLegacy("matching_tool_showtimes.*"), [isGrantedLegacy])

	const canEditPressReviews = useCallback(() => isGrantedLegacy("press_review.*"), [isGrantedLegacy])

	const canEditAlts = useCallback(() => isGrantedLegacy("alts.*"), [isGrantedLegacy])

	const canEditNews = useCallback(
		() => isGranted(PermissionTarget.BRAND_NEWS) || isGrantedLegacy("news.*"),
		[isGranted, isGrantedLegacy],
	)

	const canEditTheater = useCallback(
		() => isGrantedLegacy("theater.*") || isGranted(PermissionTarget.THEATER),
		[isGrantedLegacy, isGranted],
	)

	const canEditSecurity = useCallback(
		() =>
			isGrantedLegacy("security.*") ||
			(isGranted(PermissionTarget.SECURITY_PRESET) && isGranted(PermissionTarget.SECURITY_USER)),
		[isGrantedLegacy, isGranted],
	)

	const canEditEntityReleases = useCallback(
		() => isGrantedLegacy("release.*") || isGranted(PermissionTarget.ENTITY_RELEASES),
		[isGranted, isGrantedLegacy],
	)

	const canEditCompanyOnBrand = useCallback(
		({ brand }: { brand?: BrandOption | null }) => {
			if (canEditCompany()) return true
			return isGranted(`company.brand.${brand?.ref ?? "*"}`)
		},
		[canEditCompany, isGranted],
	)

	const canEditAdmin = useCallback(() => isGrantedLegacy("admin.*"), [isGrantedLegacy])

	const canReadCarousels = useCallback(() => isGrantedLegacy("carousel.*"), [isGrantedLegacy])

	const canReadDuplicates = useCallback(() => isGrantedLegacy("duplicate.*"), [isGrantedLegacy])

	const canReadShowtimes = useCallback(() => isGrantedLegacy("showtimes.*"), [isGrantedLegacy])

	const canReadCountry = useCallback(() => isGrantedLegacy("country.*"), [isGrantedLegacy])

	const canReadACGraphAPI = useCallback(() => isGrantedLegacy("graph.*"), [isGrantedLegacy])

	const canEditSpecialOperationOnBrand = useCallback(
		({ brand }: SecurityBrandArgs) =>
			canEditSpecialOperations() || isGrantedLegacy(`specialOperation.brand.${brand?.ref ?? "*"}`),
		[isGrantedLegacy, canEditSpecialOperations],
	)

	const canEditTheaterAlts = useCallback(() => canEditTheater() && canEditAlts(), [canEditAlts, canEditTheater])

	const canEditAuthorOnBrand = useCallback(
		({ brand }: { brand?: BrandOption | null }) => {
			if (canEditAuthor()) return true
			return isGranted(`author.brand.${brand?.ref ?? "*"}`)
		},
		[canEditAuthor, isGranted],
	)

	const canEditSecurityUser = useCallback(
		() => isGrantedLegacy("user.*") || isGranted(PermissionTarget.SECURITY_USER),
		[isGrantedLegacy, isGranted],
	)

	const canDeleteMovies = useCallback(
		() => canDeleteEntities() && isGranted(PermissionTarget.MOVIE),
		[canDeleteEntities, isGranted],
	)
	const canEditMovieOnBrand = useCallback(
		({ brand }: SecurityBrandArgs) => canEditEntityOnBrand({ brand, entityType: "Movie" }),
		[canEditEntityOnBrand],
	)
	const canEditMoviesAlts = useCallback(() => canEditMovies() && canEditAlts(), [canEditMovies, canEditAlts])

	const canEditLocalCredit = useCallback(
		({ scope, ref }: { scope: string; ref?: string }) =>
			isGrantedLegacy(`${scope}.locale.*`) ||
			canEditPersons() ||
			isGranted(PermissionTarget.ENTITY_LOCALE_DATA) ||
			(ref ? isGrantedLegacy(`${scope}.locale.${ref}`) : false),
		[canEditPersons, isGrantedLegacy, isGranted],
	)

	const canDeletePersons = useCallback(
		() => canDeleteEntities() && isGranted(PermissionTarget.PERSON),
		[canDeleteEntities, isGranted],
	)
	const canEditPersonOnBrand = useCallback(
		({ brand }: SecurityBrandArgs) => canEditEntityOnBrand({ brand, entityType: "Person" }),
		[canEditEntityOnBrand],
	)

	const canEditSeriesOnBrand = useCallback(
		({ brand }: SecurityBrandArgs) => canEditEntityOnBrand({ brand, entityType: "Series" }),
		[canEditEntityOnBrand],
	)
	const canEditSeriesAlts = useCallback(() => canEditSeries() && canEditAlts(), [canEditSeries, canEditAlts])

	const canDeleteSeriesSeason = useCallback(
		() => canDeleteSeries() || isGranted(PermissionTarget.SERIES_SEASON_DELETE),
		[canDeleteSeries, isGranted],
	)
	const canEditSeriesSeasonOnBrand = useCallback(
		({ brand }: SecurityBrandArgs) => canEditEntityOnBrand({ brand, entityType: "SeriesSeason" }),
		[canEditEntityOnBrand],
	)
	const canDeleteSeriesEpidodes = useCallback(
		() => canDeleteSeries() || isGranted(PermissionTarget.SERIES_EPISODE_DELETE),
		[canDeleteSeries, isGranted],
	)
	const canEditSeriesEpisodeOnBrand = useCallback(
		({ brand }: SecurityBrandArgs) => canEditEntityOnBrand({ brand, entityType: "SeriesEpisode" }),
		[canEditEntityOnBrand],
	)
	const canEditMovieRulingDataOnBrand = useCallback(
		({ brand }: SecurityBrandArgs) =>
			canEditEntityOnBrand({ brand, entityType: "Movie" }) && isGranted(PermissionTarget.MOVIE_BRANDED_DATA_RULING),
		[canEditEntityOnBrand, isGranted],
	)
	const canEditSeriesRulingDataOnBrand = useCallback(
		({ brand }: SecurityBrandArgs) =>
			canEditEntityOnBrand({ brand, entityType: "Series" }) && isGranted(PermissionTarget.SERIES_BRANDED_DATA_RULING),
		[canEditEntityOnBrand, isGranted],
	)
	const canEditNewsOnBrand = useCallback(
		({ brand }: SecurityBrandArgs) => canEditNews() || isGrantedLegacy(`news.brand.${brand?.ref ?? "*"}`),
		[canEditNews, isGrantedLegacy],
	)
	const canEditVideo = useCallback(
		() => isGrantedLegacy("media.video") || isGranted(PermissionTarget.MEDIA_VIDEO),
		[isGrantedLegacy, isGranted],
	)

	const canEditFestival = useCallback(() => isGranted(PermissionTarget.FESTIVAL), [isGranted])

	const canEditWidget = useCallback(() => isGranted(PermissionTarget.WIDGET), [isGranted])

	const canEditRatings = useCallback(() => isGranted(PermissionTarget.CONFIGURATION_RATINGS), [isGranted])

	const canEditLocales = useCallback(() => isGranted(PermissionTarget.STATIC_LOCALE), [isGranted])

	const canEditTrivia = useCallback(() => isGranted(PermissionTarget.BRAND_TRIVIA), [isGranted])

	const canEditEditorialReviews = useCallback(() => isGranted(PermissionTarget.BRAND_EDITORIAL_REVIEW), [isGranted])

	const canEditImage = useCallback(() => isGranted(PermissionTarget.MEDIA_IMAGE), [isGranted])

	const canEditJumpLinks = useCallback(() => isGranted(PermissionTarget.JUMP_PLATFORM_GENERATE_LINK), [isGranted])

	return {
		isAdmin,

		/**
		 * @deprecated
		 */
		isGranted,

		canEditSeoData,

		canEditJumpLinks,

		canReadImage: canEditImage,
		canEditImage,

		canReadEditorialReviews: canEditEditorialReviews,
		canEditEditorialReviews,

		canReadRatings: canEditRatings,
		canEditRatings,

		canReadLocales: canEditLocales,
		canEditLocales,

		canReadTrivia: canEditTrivia,
		canEditTrivia,

		canReadWidget: canEditWidget,
		canEditWidget,

		canReadFestival: canEditFestival,
		canEditFestival,

		canEditEntityOnBrand,
		canDeleteEntities,

		canReadSeoTags,
		canEditSeoTags,
		canEditSeoTagOnBrand,
		canEditLinkSeoTagToEntity,
		canEditLinkSeoTagToMovie,
		canEditLinkSeoTagToPerson,
		canEditLinkSeoTagToSeries,
		canEditLinkSeoTagToNews,

		canEditEntityReleases,

		canReadCompany: () => true,
		canEditCompany,
		canEditCompanyOnBrand,

		canEditTheater,
		canReadTheater: () => true,
		canEditTheaterAlts,

		canEditAdmin,
		canReadAdmin: canEditAdmin,

		canReadBoxOffice: canEditBoxOffice,
		canEditBoxOffice,

		canReadCarousels,

		canReadDuplicates,

		canReadShowtimes,

		canReadCountry,

		canReadACGraphAPI,

		canReadSpecialOperations: canEditSpecialOperations,
		canEditSpecialOperations,
		canEditSpecialOperationOnBrand,

		canEditPressReviews,
		canReadPressReviews: canEditPressReviews,

		canReadAuthor: canEditAuthor,
		canEditAuthor,
		canEditAuthorOnBrand,

		canEditKhronos,
		canReadKhronos: canEditKhronos,

		canEditMatchingTool,
		canReadMatchingTool: canEditMatchingTool,

		canEditMatchingToolShowtimes,
		canReadMatchingToolShowtimes: canEditMatchingToolShowtimes,

		canEditSecurity,
		canReadSecurity: canEditSecurity,

		canEditSecurityUser,

		canReadMovies: () => true,
		canEditMovies,
		canDeleteMovies,
		canEditMovieOnBrand,
		canEditMoviesAlts,

		canEditLocalCredit,

		canReadPersons: () => true,
		canEditPersons,
		canDeletePersons,
		canEditPersonOnBrand,

		canReadSeries: canEditSeries,
		canEditSeries,
		canEditSeriesOnBrand,
		canEditSeriesAlts,
		canDeleteSeries,

		canEditSeriesSeasons: canEditSeries,
		canDeleteSeriesSeason,
		canEditSeriesSeasonOnBrand,

		canEditSeriesEpisodes: canEditSeries,
		canDeleteSeriesEpidodes,
		canEditSeriesEpisodeOnBrand,

		canEditMovieRulingDataOnBrand,

		canEditSeriesRulingDataOnBrand,

		canEditSocialUsers,
		canEditSocialReviews,

		canEditNews,
		canReadNews: canEditNews,
		canEditNewsOnBrand,

		canEditVideo,
	}
}
