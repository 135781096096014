import { ButtonLink } from "@components/button-link"
import { Card } from "@components/card"
import { Grid } from "@components/grid"
import { useSecurity } from "@components/security"
import { AddIcon } from "@icons/add-icon"
import { VisibilityOn } from "@icons/visibility-on"

export default function Home() {
	const { canEditCompany, canEditTheater, canEditSpecialOperations } = useSecurity()

	return (
		<Grid rows="auto" gap="medium" colsLg={3}>
			<Grid gap="medium" rows="min">
				<Card header="Companies">
					<div className="flex gap-4 justify-center">
						<ButtonLink to="/companies" icon={VisibilityOn}>
							View
						</ButtonLink>
						{canEditCompany() && (
							<ButtonLink to="/companies/create" icon={AddIcon}>
								Add
							</ButtonLink>
						)}
					</div>
				</Card>
			</Grid>
			<Grid gap="medium" rows="min">
				<Card header="Theaters">
					<div className="flex gap-4 justify-center">
						<ButtonLink to="/theaters" icon={VisibilityOn}>
							View
						</ButtonLink>
						{canEditTheater() && (
							<ButtonLink to="/theaters/create" icon={AddIcon}>
								Add
							</ButtonLink>
						)}
					</div>
				</Card>
			</Grid>
			{canEditSpecialOperations() && (
				<Grid gap="medium" rows="min">
					<Card header="Special operations">
						<div className="flex gap-4 justify-center">
							<ButtonLink to="/special-operations" icon={VisibilityOn}>
								View
							</ButtonLink>
							<ButtonLink to="/special-operations/create" icon={AddIcon}>
								Add
							</ButtonLink>
						</div>
					</Card>
				</Grid>
			)}
		</Grid>
	)
}
